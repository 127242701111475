import { ERoutes } from '~/types/routes'
import { useProfileStore } from '~/stores/profileStore'

export default defineNuxtRouteMiddleware(() => {
  const { $pinia } = useNuxtApp()
  const profileStore = useProfileStore($pinia)

  if (!profileStore.isLoggedIn) {
    return navigateTo({ name: ERoutes.MyAccount })
  }
})
